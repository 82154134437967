<template>
	<div class="partial partial--artist">
		<button-gallery v-on:click.native="show = !show"></button-gallery>
		<content-band>
			<div slot="img-top" class="content-band__image content-band__image-short" :style="bioImage"></div>
			<h2 class="content-band__title">Mike Parsons</h2>
			<p>Mike is a professional tattoo artist & illustrator of over 15 years. The recipient of a multitude of awards, features & accolades, Mike has carved out a sizable niche in his industry. As Prick magazine puts it: <em>"Just like every kid who picks up a guitar needs to know Jimi Hendrix, every tattoo artist needs to know Mike Parsons."</em></p>
			
		</content-band>

		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>

		<image-grid :images="images"></image-grid>
	</div>
</template>

<script>
// Components
import ContentBand from '@/components/ContentBand'
import ImageGrid from '@/components/ImageGrid'
import ButtonGallery from '@/components/ButtonGallery'
import Artists from '@/components/Artists'

// Export
export default {
	name: 'artist',
	components: {
		ContentBand,
		ImageGrid,
		ButtonGallery,
		Artists,
	},
	data: () => ({
		bioImage: {
			backgroundImage: 'url(' + require('@/assets/images/placeholder-home-artist.jpg') + ')'
		},
		images: [
			'@/assets/images/placeholder-work.jpg',
			'@/assets/images/placeholder-work.jpg',
			'@/assets/images/placeholder-work.jpg',
			'@/assets/images/placeholder-work.jpg',
			'@/assets/images/placeholder-work.jpg',
			'@/assets/images/placeholder-work.jpg',
		],
		show: false
	}),
	metaInfo() {
		return {
			title: 'Artist Name',
			titleTemplate: '%s | Mike Parsons Ink',
		}
	},
}
</script>

<style lang="scss" scoped>

	.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 10;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}

	
</style>
